body {
    margin: 0;
    font-family: "GT Walsheim Regular", 'Helvetica Neue', sans-serif;
    /* background-color: rgb(247, 247, 247); */
    background-color: rgb(255, 255, 255);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .font-walsheim-regular {
    font-family: "GT Walsheim Regular", 'Helvetica Neue', sans-serif;
  }

  .font-walsheim-medium {
    font-family: "GT Walsheim Medium", 'Helvetica Neue', sans-serif;
  }

  .font-walsheim-bold {
    font-family: "GT Walsheim Bold", 'Helvetica Neue', sans-serif;
  }

  .font-walsheim-ultra-bold {
    font-family: "GT Walsheim Ultra Bold", 'Helvetica Neue', sans-serif;
  }
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;