.search-container {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: 126px;
  padding-top: 1px;
  padding-bottom: 1px;
  height: 65px;
}
  
.dropdown-icon {
    position: absolute;
    left: 105px;
    top: 50%;
    transform: translateY(-50%);
}