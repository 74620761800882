
  
  header {
    background-color: #FFFFFF;
    height: 90px;
    transition: all 0.3s ease-out;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 90px;
  }
  
  .logo {
    margin-left: 20px;
    padding-top: 2px;
    max-width: 150px;
    height: 47px;
    align-content: center;
  }

  .dropdown-wrapper {
    position: relative;
  }
  
  .dropdown-content {
    display: block;
    padding: 25px 48px;
    width: 180px;
    border-radius: 10px;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .header-link {
    margin-right: 15px;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #0000FF !important;
    text-decoration: none !important;
    transition: all 0.3s ease-out;
    flex-wrap: wrap;
    align-content: center;
  }
  
  .header-link:hover {
    color: #5a5aec !important;
  }