
.border-section {
    position: relative;
  }
  
.border-section::before {
    content: '';
    position: absolute;
    left: 5%; 
    right: 5%; 
    bottom: 0; 
    border-bottom: 2px solid #0000ff;
}